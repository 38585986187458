import { forwardRef } from "react";

import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from "@mui/material";

type RadioGroupComponentProps = {
  id: string;
  disabled: boolean;
  options: { value: string; name?: string; label?: string }[];
  error?: boolean;
  helperText?: string;
  value?: string;
};

const RadioGroupComponent = forwardRef(
  (
    {
      id,
      options,
      disabled = false,
      error,
      helperText,
      value: fieldValue = "",
      ...fieldProps
    }: RadioGroupComponentProps,
    ref
  ) => (
    <FormControl>
      <RadioGroup aria-labelledby={id} name={id} value={fieldValue} {...fieldProps}>
        {options.map(({ value, name, label }) => (
          <FormControlLabel
            ref={ref}
            key={value}
            value={value}
            control={<Radio />}
            label={name || label}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
);

export default RadioGroupComponent;
