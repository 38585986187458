import type { ElementType } from "react";

import { Control } from "react-hook-form";

import { TextField } from "@mui/material";

import CheckboxGroupComponent from "@components/common/Form/CheckboxGroup";
import FormFieldControlled from "@components/common/Form/FormFieldControlled";
import PhoneInput from "@components/common/Form/PhoneInput";
import RadioGroup from "@components/common/Form/RadioGroup";
import SelectDropdown from "@components/common/Form/SelectDropdown";

export const INPUT_TYPE = {
  input: TextField,
  radio: RadioGroup,
  checkbox: CheckboxGroupComponent,
  dropdown: SelectDropdown,
  phone: PhoneInput,
};

export type FieldType = keyof typeof INPUT_TYPE;

type RHFFieldSelectorComponentProps = {
  id: string;
  control: Control;
  fieldType: FieldType;
  label?: string;
  options?: { value: string; label: string }[];
  sx?: unknown;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  type?: string;
  fullWidth?: boolean;
  disabled?: boolean;
};

const RHFFieldSelector = ({
  id,
  fieldType,
  options,
  control,
  label = "", // there are cases when the label is shown by the parent component
  ...rest
}: RHFFieldSelectorComponentProps) => {
  const field: ElementType = INPUT_TYPE[fieldType];
  const isInput = fieldType === "input";
  const fieldsProps = { ...rest, ...(!isInput && { options }) };

  return (
    <FormFieldControlled
      id={id}
      name={id}
      field={field}
      control={control}
      label={label}
      {...fieldsProps}
    />
  );
};

export default RHFFieldSelector;
