import { forwardRef } from "react";

import { FormControl, FormHelperText, SelectChangeEvent } from "@mui/material";

import { selectDropdownSelectors } from "@configs/features/testSelectors";

import { SInputLabel, SMenuItem, SRadio, SSelect } from "./styles";

export type SelectDropDownOptionType = {
  value: string;
  label?: string;
  name?: string;
};

export type SelectDropDownOptionsType = SelectDropDownOptionType[];

type SelectDropdownProps = {
  options: SelectDropDownOptionsType;
  value: string;
  onChange: (event: SelectChangeEvent<unknown>) => void;
  label?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
};

const SelectDropdown = forwardRef(
  (
    {
      options,
      value,
      label,
      placeholder,
      onChange,
      error,
      helperText,
      required,
      ...rest
    }: SelectDropdownProps,
    ref
  ) => {
    return (
      <FormControl fullWidth error={error}>
        <SInputLabel required={required}>{label}</SInputLabel>
        <SSelect
          value={value}
          onChange={onChange}
          ref={ref}
          label={label}
          MenuProps={{
            sx: {
              boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
              mt: 1,
            },
            PaperProps: {
              sx: { borderRadius: "16px", height: 320 },
            },
          }}
          size="small"
          {...rest}
        >
          {placeholder && (
            <SMenuItem value="">
              <em>{placeholder}</em>
            </SMenuItem>
          )}

          {options.map(({ value: itemValue, name, label }) => {
            return (
              <SMenuItem
                key={itemValue}
                value={itemValue}
                data-testid={selectDropdownSelectors.option(itemValue)}
              >
                <SRadio
                  checked={value === itemValue}
                  value={itemValue}
                  name="radio-buttons"
                  tabIndex={-1}
                />
                {name || label}
              </SMenuItem>
            );
          })}
        </SSelect>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);

export default SelectDropdown;
