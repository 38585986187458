import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { secondary } from "@styles/colors";

export const SWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== "disabled",
})<{ disabled: boolean }>`
  border-radius: 12px;
  border: 1px solid ${secondary[30]};
  padding: 5px 20px;
  background-color: #fff;
  font-size: ${({ theme }) => theme.typography.pxToRem(17)};
  font-weight: 500;
  line-height: 140%;

  .PhoneInputCountryIconImg {
    height: 24px;
    width: 24px;
    border-radius: 12px;
  }

  .PhoneInputCountryIcon {
    height: 24px;
    width: 24px;
    background-color: transparent;
    box-shadow: none;
  }

  input {
    border: 0;
    padding: 9px;
    font: inherit;
    letter-spacing: inherit;
    color: rgba(0, 0, 0, 0.87);
    background: transparent;

    &:focus {
      outline: none;
    }
  }

  &:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events:none;
    opacity: .7;
  `}

  ${({ theme }) => theme.breakpoints.down("md")} {
    border: none;
  }
`;
