import { isValidPhoneNumber } from "libphonenumber-js";
import * as Yup from "yup";
import YupPassword from "yup-password";

YupPassword(Yup);

declare module "yup" {
  interface StringSchema {
    phoneNumber(value?: string): StringSchema;
  }
}

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const userNameValidator = Yup.string()
  .min(6, "UserName must be at least 6 characters.")
  .max(18, "UserName maximum length is 18 characters.")
  .matches(/[a-z0-9._]+/i, "Only alphabets are allowed for user name");

export const passwordValidator = Yup.string()
  .min(
    8,
    "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
  )
  .minLowercase(1, "Password must contain at least 1 lower case letter")
  .minUppercase(1, "Password must contain at least 1 upper case letter")
  .minNumbers(1, "password must contain at least 1 number")
  .minSymbols(1, "Password must contain at least 1 special character")
  .required("Password is required");

export const passwordConfirmationValidator = Yup.string().oneOf(
  [Yup.ref("password"), null],
  "Passwords must match"
);

Yup.addMethod(Yup.string, "phoneNumber", function (message) {
  return Yup.string().test("phoneNumberTest", message, (value = "") => {
    if (!value) {
      return true;
    }

    return isValidPhoneNumber?.(value);
  });
});

export const phoneNumberValidator = Yup.string()
  .phoneNumber("Phone number is not valid")
  .required("Phone number is required")
  .nullable();

export const emailValidator = Yup.string().email().required("Email is required");
