const handleResponse = async (response: Response) => {
  const data = await response.json();
  if (response.ok) {
    return data;
  }

  const error = data?.message || response.status;
  return Promise.reject(error);
};

const client = {
  get: async (url: string, headers = {}) => {
    const response = await fetch(url, { headers });
    return await handleResponse(response);
  },

  post: async <T>(url: string, body?: T, extraHeaders = {}) => {
    const headers = {
      "Content-Type": "application/json",
      ...extraHeaders,
    };
    const method = "POST";
    const response = await fetch(url, {
      body: body ? JSON.stringify(body) : undefined,
      headers,
      method,
    });
    return await handleResponse(response);
  },

  put: async <T>(url: string, body?: T, extraHeaders = {}) => {
    const headers = {
      "Content-Type": "application/json",
      ...extraHeaders,
    };
    const method = "PUT";
    const response = await fetch(url, {
      body: body ? JSON.stringify(body) : undefined,
      headers,
      method,
    });
    return await handleResponse(response);
  },
  delete: async <T>(url: string, body?: T, extraHeaders = {}) => {
    const headers = {
      "Content-Type": "application/json",
      ...extraHeaders,
    };
    const method = "DELETE";
    const response = await fetch(url, {
      body: body ? JSON.stringify(body) : undefined,
      headers,
      method,
    });

    return await handleResponse(response);
  },
};

export default client;
