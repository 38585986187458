import { Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SDialog = styled(Dialog)`
  ${({ width }: { width: string | null }) =>
    width &&
    `
    .MuiDialog-paper {
      width: ${width}
    }
  `};

  .MuiDialogContent-root {
    ${({ theme }) => theme.breakpoints.down("lg")} {
      padding: 30px 15px;
    }
  }
`;
