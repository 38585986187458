import { InputLabel, MenuItem, Radio, Select, SelectChangeEvent, styled } from "@mui/material";

import { secondary } from "@styles/colors";

export const SInputLabel = styled(InputLabel)`
  color: ${secondary[130]};
  line-height: 140%;
  transform: none;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing(1)};

  &.Mui-focused {
    color: ${secondary[130]};
  }
`;

export const SSelect = styled(Select)<{ onChange: (event: SelectChangeEvent) => void }>`
  color: ${secondary[150]};
  border-radius: 12px;

  .MuiSelect-select {
    padding: 14px 20px;
    font-size: ${({ theme }) => theme.typography.pxToRem(17)};
    font-weight: 500;
    line-height: 140%;
  }

  .MuiRadio-root {
    display: none;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${secondary[30]};
    top: 0;
  }

  legend {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    background-color: #fff;

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
`;

export const SMenuItem = styled(MenuItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  padding: 18px ${({ theme }) => theme.spacing(3)};
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.14px;

  &.Mui-selected {
    background-color: ${secondary[5]};
  }

  &:hover,
  &.Mui-selected:hover {
    background-color: ${secondary[2]};
  }
`;

export const SRadio = styled(Radio)`
  padding: 0;

  &.Mui-checked {
    color: ${secondary[100]};
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`;
