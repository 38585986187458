import { forwardRef } from "react";

import type { CountryCode } from "libphonenumber-js/core";
import metadata from "libphonenumber-js/min/metadata";
import { Control } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";

import { Stack } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

import { SWrapper } from "./styles";

export type PhoneFieldProps = {
  value: string;
  onChange: (value: string) => void;
  error?: boolean;
  helperText?: string;
  name: string;
  country?: CountryCode;
  control: Control;
  disabled: boolean;
  dataTestId?: string;
};

const PhoneField = forwardRef(
  (
    {
      name,
      value,
      error,
      onChange,
      disabled,
      helperText,
      control,
      country = "US",
      dataTestId,
    }: PhoneFieldProps,
    ref
  ) => {
    return (
      <Stack direction="column" sx={{ flexGrow: 1 }}>
        <SWrapper disabled={disabled} data-target="phone-input">
          <PhoneInput
            inputRef={ref}
            onChange={onChange}
            name={name}
            defaultCountry={country}
            displayInitialValueAsLocalNumber
            value={value}
            control={control}
            metadata={metadata}
            flagUrl="https://purecatamphetamine.github.io/country-flag-icons/1x1/{XX}.svg"
            data-testid={dataTestId}
          />
        </SWrapper>

        {error && helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Stack>
    );
  }
);

export default PhoneField;
