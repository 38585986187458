import { ReactNode } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Breakpoint, DialogActions, DialogContent, IconButton } from "@mui/material";

import { SDialog } from "./styles";

type PopupProps = {
  children: ReactNode;
  onClose?: () => void;
  dialogActions?: ReactNode;
  closeIcon?: boolean;
  width?: string | null;
  fullScreen?: boolean;
  maxWidth?: false | Breakpoint;
  className?: string;
  style?: object;
};

const Popup = ({
  onClose,
  children,
  dialogActions,
  closeIcon = true,
  width = "600px",
  fullScreen = false,
  maxWidth = "lg",
  style = { pt: "40px" },
  className,
  ...rest
}: PopupProps) => {
  return (
    <SDialog
      open
      fullWidth
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      scroll="body"
      width={width}
      fullScreen={fullScreen}
      className={className}
      {...rest}
    >
      {closeIcon && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", zIndex: 1, right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent sx={style}>{children}</DialogContent>

      {dialogActions && <DialogActions sx={{ p: "20px 24px 40px" }}>{dialogActions}</DialogActions>}
    </SDialog>
  );
};

export default Popup;
