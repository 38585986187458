import { forwardRef } from "react";

import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";

type Option = {
  value: string | number;
  name?: string | number;
  label?: string | number;
};

type CheckboxGroupComponentProps = {
  id: string;
  options: Option[];
  onChange: (value: Option["value"][]) => void;
  value: Option["value"][];
  error?: boolean;
  helperText?: string;
};

const CheckboxGroupComponent = forwardRef(
  (
    {
      options,
      onChange,
      value = [],
      error = false,
      helperText,
      ...props
    }: CheckboxGroupComponentProps,
    ref
  ) => {
    const handleChange = (selectedOption: Option["value"]) => {
      let updatedValue = [...value, selectedOption];

      if (value.includes(selectedOption)) {
        updatedValue = value.filter((option: Option["value"]) => option !== selectedOption);
      }

      onChange(updatedValue);
    };

    return (
      <FormControl>
        {options.map(({ value: optionValue, name, label }) => {
          return (
            <FormControlLabel
              ref={ref}
              key={optionValue}
              control={
                <Checkbox
                  {...props}
                  checked={value?.includes(optionValue)}
                  onChange={() => handleChange(optionValue)}
                />
              }
              label={name || label}
            />
          );
        })}
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);

export default CheckboxGroupComponent;
