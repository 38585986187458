import type { ElementType } from "react";

import type { OnboardingStepsQuestions } from "@/types";
import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";

type FormFieldControlledProps = {
  name: string;
  control: Control;
  field: ElementType;
  questions?: OnboardingStepsQuestions[];
  // eslint-disable-next-line
  [key: string]: any;
};

const FormFieldControlled = ({
  field: FieldComponent,
  name,
  control,
  questions,
  ...fieldProps
}: FormFieldControlledProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => {
      const validation = {
        ...(!!error?.message && {
          error: !!error.message,
          helperText: error.message,
        }),
      };

      return (
        <FieldComponent
          control={control}
          {...field}
          {...fieldProps}
          {...validation}
          questions={questions}
        />
      );
    }}
  />
);

export default FormFieldControlled;
